import emailjs from "@emailjs/browser";

class Email {
    sendEmail(data){
        let templateParams = {
            to_name: "Laura and Nadia",
            from_name: "Oregon-Law",
            name: data.name,
            email: data.email,
            phone: data.phone,
            message: data.message
        }

        return emailjs.send("service_2m9zy37", "template_3wj6jxb", templateParams, {publicKey: 'UVTzjd17xw71Rs-tf'})
            .then((response) => {
                console.log('Success!', response.status, response.text);
                return Promise.resolve(response);
            }).catch((error) => {
                console.log('Failed...', error);
                return Promise.reject(error);
            });
    }
}

export default Email;