import { Component } from "react";
import NameField from "./FormFields/NameField";
import PhoneField from "./FormFields/PhoneField";
import EmailField from "./FormFields/EmailField";
import MessageField from "./FormFields/MessageField";
import Email from "./Email";

class ContactForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            name: null,
            phone: null,
            email: null,
            message: null
        }
        this.email = new Email;
    }

    setName(name){
        this.setState({
            name: name
        });
    }

    setPhone(phone){
        this.setState({
            phone: phone
        });
    }

    setEmail(email){
        this.setState({
            email: email
        });
    }

    setMessage(message){
        this.setState({
            message: message
        });
    }

    validate(){
        if (this.state.name === null) {
            return new Error("You must enter a name before submitting.")
        }
        if (this.state.phone === null && this.state.email === null) {
            return new Error("You must enter a valid phone or email before submitting.")
        }
        if (this.state.message === null) {
            return new Error("You must enter a message before submitting.")
        }
        return null;
    }

    submit(){
        let error = this.validate();
        if (error === null) {
            this.email.sendEmail(this.state).then(() => {
                    window.location.reload();
                    alert("Your submission was succesful.");
                })
                .catch(() => {
                    alert("We had an issue submitting your form. Please try again.");
                });
        }else {
            alert(error);
        }
    }

    validateEs(){
        if (this.state.name === null) {
            return new Error("Debe ingresar un nombre antes de enviar.")
        }
        if (this.state.phone === null && this.state.email === null) {
            return new Error("Debe ingresar un teléfono o correo electrónico válido antes de enviar.")
        }
        if (this.state.message === null) {
            return new Error("Debe ingresar un mensaje antes de enviar.")
        }
        return null;
    }

    submitEs(){
        let error = this.validateEs();
        if (error === null) {
            this.email.sendEmail(this.state)
                .then(() => {
                    window.location.reload();
                    alert("Su envío fue exitoso.");
                })
                .catch(() => {
                    alert("Tuvimos un problema al enviar su formulario. Por favor inténtalo de nuevo.");
                });
        }else {
            alert(error);
        }
    }

    render(){
        return (
            <div className="ContactForm">
                <div className="FormContainer">
                    <NameField onChange={this.setName.bind(this)}/>
                    <PhoneField onChange={this.setPhone.bind(this)}/>
                    <EmailField onChange={this.setEmail.bind(this)}/>
                    <MessageField onChange={this.setMessage.bind(this)}/>
                    {"eng" === localStorage.getItem("language") ? (
                        <div>
                            <div className="Required">(* indicates a required field)</div>
                            <button className="Submit" onClick={this.submit.bind(this)}>Submit</button>
                        </div>
                    ): "es" === localStorage.getItem("language") ? (
                        <div>
                            <div className="Required">(* indica un espacio requerido)</div>
                            <button className="Submit" onClick={this.submitEs.bind(this)}>Entregar</button>
                        </div>
                    ):(
                        <div>Please select a Language above</div>
                    )}
                </div>
            </div>
        );
    }
}

export default ContactForm;